
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

const swiper = new Swiper(".swiper--default", {
    modules: [Navigation],
    slidesPerView: 2.4,
    spaceBetween: 30,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        "0": {
          slidesPerView: 1.2,
        },
        "767": {
          slidesPerView: 2.4,
        }
    },
});


const alertSwiper = new Swiper(".swiper--alert", {
  modules: [Navigation],
  slidesPerView: 0.8,
  spaceBetween: 30,
  navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    "0": {
      slidesPerView: 0.8,
    },
    "767": {
      slidesPerView: 1.2,
    }
},
});
