const seasonSwitcher = document.querySelector('[data-behaviour=\'season-switcher\']');

if(seasonSwitcher) {

    const toggle = seasonSwitcher.querySelector('[data-behaviour=\'switcher-toggle\']');
    const primaryTemplate = document.querySelector('[data-behaviour=\'template-primary\']');
    const secondaryTemplate = document.querySelector('[data-behaviour=\'template-secondary\']');

    if(toggle) {
        toggle.addEventListener('click', (e) => {

            document.body.classList.toggle('is-active');
            document.body.classList.add('is-transitioning');

            setTimeout(() => {
                document.body.classList.remove('is-transitioning');
            }, 2000);

            setTimeout(() => {
                if(document.body.classList.contains('is-active')) {
                    primaryTemplate.classList.remove('is-active');
                    secondaryTemplate.classList.add('is-active');
                } else {
                    primaryTemplate.classList.add('is-active');
                    secondaryTemplate.classList.remove('is-active');
                }
            }, 500);

        });
    }

};